.content-production-card {
  display: flex;
  align-items: center;

  &__text {
    & > &__title {
      color: var(--Shark, #FFF);
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 130%;
      letter-spacing: -0.02em;
      text-align: left;
    }

    & > &__sub-title {
      color: var(--Medaka, #D6D6D6);

      font-size: .875rem;
      font-weight: 400;
      line-height: 142.857%;
      text-align: left;
    }
  }
}
