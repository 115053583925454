.golden-offer {
  position: relative;
  width: 100%;
  bottom: 0;
  max-width: 450px;

  border-radius: .6rem;

  color: var(--Shark, #FFF);

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 1rem;
    border-radius: .6rem;

    background-color: #ffbf0014;
    background-image: url(../../../../public/images/backgrounds/bg-gradient-gold-metal-border.svg);

    &__price-description {
      margin-left: 1rem;

      &__name {
        color: #FFBF00;
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 130%;
      }
      
      & > &__month-price {
        font-family: Inter;
        font-size: 2rem;
        font-weight: 700;
        line-height: 2rem;
        letter-spacing: -0.02em;
        text-align: left;
      }

      &__text-below {
        color: var(--Medaka, #D6D6D6);
        font-size: .875rem;
        font-weight: 400;
        line-height: 142.857%;
        letter-spacing: 0.14px;
      }
    }

    &__delivery {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 5.25rem;
      height: 5.25rem;

      border-radius: 1rem;
      font-size: 1.5rem;
      font-weight: 600;

      background-image: url(../../../../public/images/backgrounds/bg-gradient-gold-metal-border.svg);

      &__text {
        font-size: .625rem;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.02em;
      }

      &__time {
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.02em;
      }
    }
  }


  &__claim-offer {
    margin-top: 2rem;
    padding: 1rem 0;
    width: 100%;
    border-radius: .5rem;

    background: linear-gradient(87deg, #FFBF00 7.55%, #C39200 117.22%);

    color: var(--Shark, #FFF);
    font-size: 1.5rem;

    border: 1px solid;
    border-image: linear-gradient(87.69deg, #1B1B1B -2.1%, #FFFFFF 48.95%, #1A1A1A 100%) 10;

    color: var(--Tuna, #1B1C1F);
    font-weight: 600;
    line-height: 125%;

    &:disabled {
      opacity: .7;
    }

    &__loader {
      width: fit-content;
      margin: 0 auto;
    }
  }
}
