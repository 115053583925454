@import "../../components/App/App";

.vip-paywall-page {
  background: var(--Tautog, #000);
  flex-direction: column;
  align-items: center;
  padding: 0 1rem 2rem 1rem;

  background-image: url(../../../public/images/backgrounds/golden-bg-center.svg);
  background-repeat: no-repeat;
  background-position: center -90rem;
  background-size: 100% 80%;

  & h2 {
    @include  XXXL32;
    font-weight: 700;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  &__cross {
    position: fixed;
    z-index: 1;
    top: 1rem;
    left: 1rem;

    @media screen and (min-width: 450px) {
      & {
        left: 50%;
        transform: translate(-50%);

        margin-left: -12rem;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;

    & .logo {
      width: 42px;
      height: 36px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;

    background-size: 80%;
    background-position: center 10rem;
    background-repeat: no-repeat;
    background-image: url(../../../public/images/backgrounds/golden-bg-center.svg);

    &__title {
      margin-top: 1rem;
      text-align: center;

      background: linear-gradient(90deg, #FFDB70 0%, #FFBF00 100%);
      background-clip: text;
      -webkit-text-fill-color: transparent;

      font-size: .875rem;
      font-weight: 500;
    }

    &__top-image {
      width: 100%;
      margin-top: 2rem;
      object-fit: contain;
    }

    &__exclusive-proposition {
      & > *:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      max-width: 30rem;
      color: var(--Shark, #FFF);

      &__title {
        text-align: center;
        margin: 4rem 0 1rem 0;
        font-size: 1.25rem;
      }
    }

    &__content-production {
      & h3 {
        text-align: center;
        color: var(--Shark, #FFF);
        font-size: 1.25rem;
      }
    }

    &__reviews {
      margin: 0 -1rem;
    }

    &__focus-what-matters {
      position: relative;
      height: 60svh;
      margin-left: -1.5rem;
      margin-right: -1.5rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        width: 100%;
        height: 100%;
        background-image: url(../../../public/images/backgrounds/bg10.png);
        background-size: cover;
        background-repeat: no-repeat;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        transform: scale(1.125);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow:
          inset 0 3rem 3rem 3rem #000,
          inset 0 -6rem 2rem 1rem #000;
      }
    }

    &__special-offer {
      position: relative;
      top: -10rem;
      margin-bottom: -10rem;
    }

    &__changed-mind {
      margin-top: 2rem;

      & > h2 {
        font-family: DM Sans;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 2rem;
      }

      & > h3 {
        color: var(--Shark, #FFF);
        font-family: DM Sans;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        opacity: .7;
        text-align: center;
      }
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & button {
      @include  MD16;
      font-weight: 500;
      min-height: 48px;
      padding: 12px 1.5rem;
      border-radius: 8px;
      background: var(--Tuna, #202020);
      margin-bottom: 3%;

      color: var(--Shark, #FFF);
      font-weight: 540;
    }

    &__rights {
      display: flex;
      justify-content: space-between;

      width: 100%;

      margin-top: 2rem;
      margin-bottom: 1rem;

      font-size: .75rem;
      color: var(--Medaka, #D6D6D6);
      text-align: center;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.12px;

      & > div {
        & > a {
          color: var(--Medaka, #D6D6D6);
          text-decoration-line: underline;
        }
      }
    }
  }
}
