@import "../../../components/App/App";

.vip-services-hoc {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;

    background-image: url(../../../../public/images/backgrounds/red-bg-top.svg);
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;

    & a, & img, & > div {
      width: 42px;
      height: 36px;
    }
  }

  &__main {
    background-color: transparent;
    &__top {
      & > &__label {
        color: #FFBF00;
        text-align: center;
        font-size: .875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 142.857%;
        letter-spacing: 1.68px;
        text-transform: uppercase;
      }
  
      &__title {
        padding: 0 .75rem;
        white-space: pre-line;
      }
    }

    &__main-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-top: 1rem;
      height: 62.5svh;
      overflow: hidden;

      &__image {
        position: relative;
        margin-top: 1rem;
        width: 100%;

        &__img-tag {
          height: 120%;
          object-fit: cover;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: -1px;
          display: block;
          width: 100%;

          height: 125%;
          background: linear-gradient(#00000010, #00000050, #00000010);
          box-shadow:
            inset 0 3rem 3rem .25rem #000,
            inset 0 -3rem 3rem .25rem #000;
        }
      }
    }
  }

  &__footer {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1.25rem;
    left: 50%;
    translate: -50%;
    position: fixed;
    bottom: 2rem;

    &__text {
      text-align: center;
      font-size: 1.25rem;
      line-height: 130%;
      white-space: pre-line;
      color: var(--Shark, #FFF);
    }

    &__button {
      width: 215px;
      min-height: 48px;
      padding: 12px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--Shark, #FFF);
      margin-bottom: 2.5%;

      color: var(--Tuna, #202020);
      font-family: Inter;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 133.333%;
    }
  } 

  & h2 {
    @include  XL24;
    letter-spacing: -0.05em;
  }

  & h3 {
    @include  XXS10;
    align-self: stretch;
    margin-top: 2%;
    color: var(--Gulper, #9A9EA6);
    margin-bottom: 3%;
  }
}
