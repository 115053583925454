.team-member-card {
  position: relative;

  display: flex;

  margin: 1rem 1rem;
  padding: 1rem;

  &__image {
    border-radius: 1rem;

    max-width: 40%;
    object-fit: cover;
  }

  &__right-block {
    margin-left: 1rem;

    & > &__role {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 133.333%;

      color: #ffbf00;
    }

    & > &__title {
      text-align: start;
      font-size: 1rem;
      font-weight: 600;
      line-height: 137.5%;
    }

    &__key-tasks {
      margin-top: .5rem;

      color: var(--Medaka, #D6D6D6);
      font-size: .875rem;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 1.44px;
      text-transform: uppercase;
      text-align: left;

      &__list {
        li {
          color: var(--Medaka, #D6D6D6);
          letter-spacing: -0.01em;
          text-transform: none;

          &::before {
            content: '✓';
            color: #FFBF00;
            margin: 0 .5rem;
          }
        }
      }
    }
  }

  &::after {
    content: '';
    position: absolute;

    bottom: -1rem;
    width: 100%;
    border: 1px solid #464646;
  }
}
