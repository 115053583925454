@import "../../components/App/App";

.paywall-page {
  background: var(--Tautog, #000);
  flex-direction: column;
  align-items: center;
  padding: 0 1rem 2rem 1rem;

  background-image: url(../../../public/images/backgrounds/red-bg-center.svg);
  background-repeat: no-repeat;
  background-position: center -110rem;
  background-size: 100% 80%;

  & h2 {
    @include  XXXL32;
    line-height: 125%;
    letter-spacing: -0.03em;
    font-weight: 700;
    margin-top: 3%;
  }

  &__cross {
    position: fixed;
    z-index: 1;
    top: 1rem;
    left: 1rem;

    @media screen and (min-width: 450px) {
      & {
        left: 50%;
        transform: translate(-50%);

        margin-left: -12rem;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;

    & .logo {
      width: 42px;
      height: 36px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;

    
    background-image: url(../../../public/images/backgrounds/red-bg-center.svg);
    background-size: 100%;
    background-position: center 1rem;
    background-repeat: no-repeat;

    &__easy-to-follow-guide {
      @include  XL24;
      color: var(--Shark, #FFF);
      opacity: .7;
    }

    &__guide-slides {
      margin: 0 -1rem;
    }

    &__quotes {
      position: relative;

      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 70%;
      margin: 2rem auto 2.5rem auto;
      padding: 1rem 0;

      border: .5px solid;
      border-image: linear-gradient(87.36deg, rgba(65, 67, 70, 0.1) 23.06%, #6E727B 47.12%, rgba(65, 67, 70, 0.1) 81.03%) 10;

      background-image: url(../../../public/images/icons/quote-start.svg);
      background-repeat: no-repeat;
      background-position-y: .75rem;

      font-family: Fraunces;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;

        background: linear-gradient(to left,  #1411111a 10%, #48484c33 50%, #1411111a 90%);
      }

      &__text {
        color: var(--Shark, #FFF);
        font-family: inherit;
      }

      &__extra-info {
        display: flex;

        &__text {
          margin-left: 1rem;

          &__title {
            color: var(--Shark, #FFF);
          }

          &__description {
            color: var(--Gulper, #9A9EA6);
            font-size: 12px;
          }
        }
      }
    }

    &__exclusive-proposition {
      & > *:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      max-width: 30rem;
      color: var(--Shark, #FFF);

      & &__title {
        margin: 4rem 1rem 1rem 1rem;
      }
    }

    &__video-text-comparison {
      position: relative;
      width: 100%;
      transform: scale(1.125);
    }

    &__video-text-gitnux-research {
      position: relative;
      top: -2rem;

      padding: 1rem 1.5rem;

      border-radius: var(--Corner-Radius-SM, 16px);
      background: var(--Tuna, #202020);
      backdrop-filter: blur(8px);
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;

        top: 1.5rem;
        left: 50%;
        transform: translate(-50%);

        box-shadow: 0 0 7rem 1rem #E09A00;
      }

      &__quote {
        width: 10%;
      }

      &__top-text {
        font-family: Fraunces;
        font-size: 1.5rem;
        font-weight: 445;
        font-style: normal;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: var(--Shark, #FFF);
      }

      &__logo {
        width: 5rem;
      }

      &__bottom-text {
        font-size: 13px;
        font-style: italic;
        color: var(--Gulper, #9A9EA6);
      }
    }
    
    &__app-rating {
      width: 100%;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      padding: 2rem 0;
      background: no-repeat center url(../../../public/images/thousands-trust-us-rating-noise.svg);

      &__image {
        display: block;
        margin: 0 auto;
        width: 55%;
      }
    }

    &__reviews {
      margin: 0 -1rem;
    }

    &__awards {
      width: 100%;
      margin-top: 10%;

      &__award-img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: .75rem;
        background-color: var(--Tuna, #202020);
        padding: .75rem 1rem;

        height: 3rem;
        width: 100%;

        & > img {
          width: 45%;
        }
      }

      &__center {
        width: 100%;
        display: flex;
        justify-content: space-between;

        & > div {
          width: 100%;
          margin: .75rem 0;

          &:nth-child(1) { margin-right: .375rem; }
          &:nth-child(2) { margin-left: .375rem; }
        }
      }
    }

    &__establish-your-brand {
      width: 100%;
    }

    &__guaranteed-best-value {
      width: 100%;
    }

    &__get-custom-website {
      @include  LG20;
      opacity: .7;
    }

    &__personal-brand-in-5-mins {
      position: relative;
      height: 60svh;
      margin-left: -1.5rem;
      margin-right: -1.5rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        width: 100%;
        height: 100%;
        background-image: url(../../../public/images/backgrounds/bg6.png);
        background-size: cover;
        background-repeat: no-repeat;
      }

      h2 {
        margin-left: 1.5rem;
        text-align: start;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        transform: scale(1.125);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow:
          inset 0 3rem 3rem 3rem #000,
          inset 0 -6rem 4rem 3rem #000;
      }
    }

    &__special-offer {
      position: relative;
      top: -10rem;
      margin-bottom: -10rem;
    }

    &__awards {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5% 0;

      &__top {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        & img {
          margin: 0 .75rem;
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        & img {
          margin: 0 12px;
        }
        }
    }
  }

  &__footer {
    width: 100%;

    &__team-needed {
      & > h2 {
        @include  XXXL32;
        font-weight: 700;
        margin-top: 3%;
      }

      & > h3 {
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-size: 1.25rem;
        text-align: center;
        color: var(--Shark, #FFF);
      }

      &__employees {
        display: flex;
        justify-content: space-between;

        &__employee {
          margin: 0 1rem;

          &__title {
            margin-top: 1rem;
            margin-bottom: 2rem;
            font-size: 1rem;
            font-weight: 600;
            text-align: center;
            color: var(--Shark, #FFF);
          }
        }
      }

      &__pricing {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        border-radius: 2rem;
        padding: 1.25rem;

        background: linear-gradient(90deg, rgba(255, 255, 255, 0.0216) 0%, rgba(255, 191, 0, 0.08) 50%);

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          border-radius: 2rem;
          border: 2px solid transparent;
          background-image:
            linear-gradient(#000, #000),
            linear-gradient(20.49deg, #FFD400 -150.48%, #0C0C0C 102.27%);
          background-origin: border-box;
          background-clip: content-box, border-box;
          z-index: -1;
        }

        &__text {
          &__vip {
            background: linear-gradient(90deg, #FFDB70 0%, #FFBF00 100%);
            background-clip: text;
            -webkit-text-fill-color: transparent;

            font-size: 1.125rem;
            font-weight: 600;
            line-height: 133.333%;
          }
        }

        &__button {
          width: 35%;
          height: 3rem;

          border-radius: .5rem;
          background-color: var(--Shark, #FFF);

          color: var(--Tuna, #202020);
          font-size: 1.125rem;
          font-weight: 600;
          line-height: 133.333%;
        }
      }
    }

    &__rights {
      display: flex;
      justify-content: space-between;

      margin-top: 4rem;
      margin-bottom: 3rem;

      font-size: .75rem;
      color: var(--Medaka, #D6D6D6);
      text-align: center;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.12px;

      & > div {
        & > a {
          color: var(--Medaka, #D6D6D6);
          text-decoration-line: underline;
        }
      }
    }
  }
}
