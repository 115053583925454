@import "../../components/App/App";

@keyframes spin {
  100% { transform: rotate(360deg); }
}

.thousands-trust-us-page {
  background-color: var(--Tautog, #000);
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden; 

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding: 20px 3% 5%;

    background-image: url(../../../public/images/backgrounds/red-bg-top.svg);
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;

    z-index: 2;
    & > * {
      width: 42px;
      height: 36px;
    }

    & .logo {
      width: 42px;
      height: 36px;
    }
  }
  &__main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;

    width: 100%;
    margin-bottom: 2rem;

    &__app-rating {
      width: 100%;

      margin-bottom: calc(min(2.5rem, 2svh));

      padding: calc(min(1rem, 1svh)) 0 3svh 0;
      background: no-repeat center url(../../../public/images/thousands-trust-us-rating-noise.svg);
      background-size: 100%;
      &__image {
        display: block;
        margin: 0 auto;
        width: calc(min(50%, 30svh));
      }
    }
  }

  &__footer {
    position: fixed;
    bottom: 2rem;
    z-index: 2;

    & button {
      @include  MD16;
      width: 215px;
      min-height: 48px;
      padding: 12px 20px;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: var(--Shark, #FFF);
      margin-bottom: 5%;
      font-weight: 540;
  }}

  & h2 {
    @include  XL24;
  }

  & h3 {
    @include  XXS10;
    align-self: stretch;
    margin-top: 2%;
    color: var(--Gulper, #9A9EA6);
    margin-bottom: 3%;
    // needed as uppercases slider's h3
    text-transform: none;
  }

}
